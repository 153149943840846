#dashboard{
	tbody{
		.success{
			background: rgb(76, 175, 80) !important;
		}
		.danger{
			background: rgba(244,67,54,0.5) !important;
		}
	}
	.has-focus {
		background:rgb(174, 199, 232);
		color:white;
	}
}