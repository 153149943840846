.date-picker-class{
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
	max-width: 600px;
}
#page_activity{
	/* Progress Tracker v2 */
	ol.progress[data-steps="2"] li { width: 49%; }
	ol.progress[data-steps="3"] li { width: 33%; }
	ol.progress[data-steps="4"] li { width: 24%; }
	ol.progress[data-steps="5"] li { width: 19%; }
	ol.progress[data-steps="6"] li { width: 16%; }
	ol.progress[data-steps="7"] li { width: 14%; }
	ol.progress[data-steps="8"] li { width: 12%; }
	ol.progress[data-steps="9"] li { width: 11%; }

	.progress {
		width: 100%;
		list-style: none;
		list-style-image: none;
		margin: 20px 0 20px 0;
		padding: 0;
	}

	.progress li {
		float: left;
		text-align: center;
		position: relative;
	}

	.progress .name {
		display: block;
		vertical-align: bottom;
		text-align: center;
		margin-bottom: 1em;
		color: rgb(92,97,111);
		opacity: 0.3;
	}

	.progress .step {
		color: rgb(92,97,111);
		border: 3px solid rgb(92,97,111);
		background-color: rgb(92,97,111);
		border-radius: 50%;
		line-height: 1.2;
		width: 1.2em;
		height: 1.2em;
		display: inline-block;
		z-index: 0;
	}

	.progress .step span {
		opacity: 0.3;
	}

	.progress .active .name,
	.progress .active .step span {
		opacity: 1;
	}

	.progress .step:before {
		content: "";
		display: block;
		background-color: rgb(92,97,111);
		height: 0.4em;
		width: 50%;
		position: absolute;
		bottom: 0.6em;
		left: 0;
		z-index: -1;
	}

	.progress .step:after {
		content: "";
		display: block;
		background-color: rgb(92,97,111);
		height: 0.4em;
		width: 50%;
		position: absolute;
		bottom: 0.6em;
		right: 0;
		z-index: -1;
	}

	.progress li:first-of-type .step:before {
		display: none;
	}

	.progress li:last-of-type .step:after {
		display: none;
	}

	.progress .done .step,
	.progress .done .step:before,
	.progress .done .step:after,
	.progress .active .step,
	.progress .active .step:before {
		background-color: rgb(41,182,246);
	}

	.progress.cancelled .done .step,
	.progress.cancelled .done .step:before,
	.progress.cancelled .done .step:after,
	.progress.cancelled .active .step,
	.progress.cancelled .active .step:before {
		background-color: rgb(244,67,54);
	}

	.progress.hold .done .step,
	.progress.hold .done .step:before,
	.progress.hold .done .step:after,
	.progress.hold .active .step,
	.progress.hold .active .step:before {
		background-color: rgb(255,214,0);
	}

	.progress .done .step,
	.progress .active .step {
		border: 3px solid rgb(41,182,246);
	}

	.progress.cancelled .done .step,
	.progress.cancelled .active .step {
		border: 3px solid rgb(244,67,54);
	}

	.progress.hold .done .step,
	.progress.hold .active .step {
		border: 3px solid rgb(255,214,0);
	}
}
