#time-management{
	tbody{
		.success{
			background: rgb(76, 175, 80) !important;
		}
		.danger{
			background: rgba(244,67,54,0.5) !important;
		}
	}
	.has-focus {
		background:rgb(174, 199, 232);
		color:white;
	}
	td > input{
		background-color: rgba(255,255,255,0);
	}

	.header{
		min-height: 120px;
		height: 120px;
	}

	.logo {
		z-index: 10;
	    .logo-icon {
	        margin: 0 16px 0 0;

	        md-icon {
	            color: #FFFFFF;
	        }
	    }

	    .logo-text {
	        color: #FFFFFF;
	        font-size: 20px;
	        font-weight: 500;
	        white-space: nowrap;
	    }
	}

	.top-bg{
		height: 120px;
	}

	.bottom {
		margin-top: 8px;
		z-index: 10;
		.search {
			height: 48px;
			min-height: 48px;
			padding: 8px 8px 8px 16px;
			background: #FFFFFF;

			.icon {
				margin: 0;
			}

			input {
				padding-left: 16px;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		.date-filter-box {
			background-color: white;
			height: 48px;
			padding-left: 16px;
			margin-left: 8px;

			.filter {

				md-datepicker {
					margin-right: 0;
				}
			}
		}
	}
}
