#user-settings {

    .left-sidenav {

        .contacts-view {

            md-toolbar {

                .md-toolbar-tools {

                }

                .md-toolbar-tools-top {

                }

                .md-toolbar-tools-bottom {

                }

            }

            md-content {

                .contact-list {

                    .contact {

                        .md-button {
                            padding-left: 40px;
                        }

                        .fist-letter {
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 8px 12px;
                            font-size: 18px;
                            font-weight: 300;
                            z-index: 9999;
                        }

                        .name {

                        }

                        .mood {

                        }
                    }
                }
            }
            .contacts-button {
                position: absolute;
                bottom: 4px;
                right: 4px;
            }
        }
    }
}
